import styled from "styled-components";
import {
    highlightBlue,
    highlightBrown,
    highlightDarkBlue,
    highlightGreen,
    highlightGrey,
    highlightOrange,
    highlightPink,
    highlightPurple,
    highlightRed,
    highlightYellow,
    backgroundColorLevelCurrent,
    getAppearanceConstant,
    setAppearanceConstantLocal,
} from "@churchofjesuschrist/eden-style-constants";

export const highlightColors = {
    blue: highlightBlue,
    brown: highlightBrown,
    dark_blue: highlightDarkBlue,
    gray: highlightGrey,
    green: highlightGreen,
    orange: highlightOrange,
    pink: highlightPink,
    purple: highlightPurple,
    red: highlightRed,
    yellow: highlightYellow,
    clear: backgroundColorLevelCurrent,
};

/**
 * Unity doesn't have underline highlight colors defined and the normal highlight colors are too light to be used as underlines. The below removes the alpha hex from the provided highlight colors which makes them stronger versions of the same and more suitable for underlines. This isn't officially blessed so we may need to re-visit this at some point.
 */
const underlines = {};
for (const [key, value] of Object.entries(highlightColors)) {
    underlines[key] = {};
    // Get the unity values for the highlight version
    underlines[key].values = getAppearanceConstant(value);
    // Use the setAppearanceConstantLocal helper to add some light/dark values that will work with Appearance
    underlines[key].constantLocal = setAppearanceConstantLocal(
        `${key}Underline`,
        {
            // use the existing light/dark colors and just get the first 6 characters to remove the alpha
            light: underlines[key].values.light.match(/#[a-fA-F0-9]{6}/)?.[0],
            dark: underlines[key].values.dark.match(/#[a-fA-F0-9]{6}/)?.[0],
        }
    );
    // save off the definition and css variables created by the helper
    underlines[key].def = underlines[key].constantLocal[0];
    underlines[key].var = underlines[key].constantLocal[1];
}
export const Mark = styled.mark`
    /* inject the css to "Def"ine the custom properties */
    ${underlines.blue.def}
    ${underlines.brown.def}
    ${underlines.dark_blue.def}
    ${underlines.gray.def}
    ${underlines.green.def}
    ${underlines.orange.def}
    ${underlines.pink.def}
    ${underlines.purple.def}
    ${underlines.red.def}
    ${underlines.yellow.def}
    ${underlines.clear.def}

    background: ${({ clear, color: colorKey, underline }) => {
        const color = highlightColors[clear ? "clear" : colorKey];

        return underline || clear ? "transparent" : color;
    }};
    box-shadow: ${({ clear, color: colorKey, underline }) => {
        const color = underlines[clear ? "clear" : colorKey].var;

        return underline ? `inset 0 -2px 0 ${color()}` : "unset";
    }};
    color: inherit;
`;

export default Mark;
